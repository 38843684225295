module.exports = [{
      plugin: require('/Volumes/Talon02/Projects/2020/penta-website/node_modules/gatsby-plugin-fixhash/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":50,"scrollToOptions":{"behavior":"smooth"}},
    },{
      plugin: require('/Volumes/Talon02/Projects/2020/penta-website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBMCPD8","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n                    return {\n                        platform: \"Penta US Website\",\n                        pageType: window.pageType\n                    };\n                }"}},
    },{
      plugin: require('/Volumes/Talon02/Projects/2020/penta-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
