/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  import(`intersection-observer`);

if (typeof window.IntersectionObserver === `undefined`) {
		import(`intersection-observer`);
	//	console.log(`# IntersectionObserver is polyfilled!`);
	} 
};
 */
export const onClientEntry = () => import(`intersection-observer`);
