// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Volumes/Talon02/Projects/2020/penta-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Volumes/Talon02/Projects/2020/penta-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-news-js": () => import("/Volumes/Talon02/Projects/2020/penta-website/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-projects-js": () => import("/Volumes/Talon02/Projects/2020/penta-website/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-team-js": () => import("/Volumes/Talon02/Projects/2020/penta-website/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

